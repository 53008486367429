import React, { Component } from 'react'
import { Form } from 'react-bootstrap'
import { sendEmail } from '../services/EmailService'

export class ContactForm extends Component {
  state = {
    name: '',
    email: '',
    telephone: '',
    details: '',
  }

  handleName(event) {
    if(event.target.value !== '') {
      this.setState({name: event.target.value})
      console.log(this.state.name)
    }
  }

  handlePhone(event) {
    if(event.target.value !== '') {
      this.setState({telephone: event.target.value})
      console.log(this.state.telephone)
    }
  }

  handleEmail(event) {
    if(event.target.value !== '') {
      this.setState({email: event.target.value})
      console.log(this.state.email)
    }
  }

  handleDetails(event) {
    if(event.target.value !== '') {
      this.setState({details: event.target.value})
      console.log(this.state.details)
    }
  }

  send = (nameData, emailName, telephoneData, detailsData) => {
    const emailData = {
      name: nameData,
      email: emailName,
      telephone: telephoneData, 
      details: detailsData
    }
    console.log(emailData)
    sendEmail(emailData)
  }

    render() {
        return (
            <section className="page-section" id="contact">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 text-center">
                  <h2 className="section-heading text-uppercase">Contact Us</h2>
                  <h3 className="section-subheading text-muted">Lorem ipsum dolor sit amet consectetur.</h3>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <form id="contactForm" name="sentMessage" novalidate="novalidate">
                    <div className="row">
                      <div className="col-md-6">
                        <Form className='form-group'>
                          <Form.Group controlId= 'form-control'>
                            <Form.Control type='text' placeholder='Your Name *' onChange={(event) => this.handleName(event)}/>
                          </Form.Group>
                        </Form>
                        <Form className='form-group'>
                          <Form.Group controlId= 'form-control'>
                            <Form.Control type='email' placeholder='Your Email *' onChange={(event) => this.handleEmail(event)}/>
                          </Form.Group>
                        </Form>
                        <Form className='form-group'>
                          <Form.Group controlId= 'form-control'>
                            <Form.Control type='phone' placeholder='Your Phone *' onChange={(event) => this.handlePhone(event)}/>
                          </Form.Group>
                        </Form>
                      </div>
                      <div className="col-md-6">
                      <Form className='form-group'>
                          <Form.Group controlId= 'form-control'>
                            <Form.Control id='message' type='text' placeholder='Your Message *' onChange={(event) => this.handleDetails(event)}/>
                          </Form.Group>
                        </Form>f
                      </div>
                      <div className="clearfix"></div>
                      <div className="col-lg-12 text-center">
                        <div id="success"></div>
                        <button onClick = {this.send(this.state.name, this.state.email, this.state.telephone, this.state.details)} id="sendMessageButton" className="btn btn-primary btn-xl text-uppercase" type="submit">Send Message</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        )
    }
}