import React, { Component } from 'react'
import logo from '../assets/img/trucking1.jpg'
 
const Portfolio = () => {
        return (
          <section className="bg-light page-section" id="portfolio">
    <div className="container">
      <div className="row">
        <div className="col-lg-12 text-center">
          <h2 className="section-heading text-uppercase">Portfolio</h2>
          <h3 className="section-subheading text-muted">Here's some information about our experience.</h3>
        </div>
      </div>
      <div className="row">
        <div className="col-md-4 col-sm-6 portfolio-item">
          <a className="portfolio-link" data-toggle="modal" href="#portfolioModal1">
            <div className="portfolio-hover">
              <div className="portfolio-hover-content">
                <i className="fa fa-plus fa-3x"></i>
              </div>
            </div>
            <img className="img-fluid" src={logo} alt=""/>
          </a>
          <div className="portfolio-caption">
            <h4>Trucking</h4>
            <p className="text-muted">Information</p>
          </div>
        </div>
    </div>
    </div>
  </section>
        )
    };

export default Portfolio;
