import React, { Component } from 'react'

export class ServiceInfo extends Component {
    render() {
        return (
            <section className="page-section" id="services">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 text-center">
                  <h2 className="section-heading text-uppercase">Services</h2>
                  <h3 className="section-subheading text-muted">Check out what we have to offer</h3>
                </div>
              </div>
              <div className="row text-center">
                <div className="col-md-4">
                  <span className="fa-stack fa-4x">
                    <i className="fa fa-circle fa-stack-2x text-primary"></i>
                    <i className="fa fa-truck fa-stack-1x fa-inverse"></i>
                  </span>
                  <h4 className="service-heading">Dedicated truckload service</h4>
                  <p className="text-muted">Experienced management with a wealth of knowledge and guaranteed customer satisfaction.</p>
                </div>
                <div className="col-md-4">
                  <span className="fa-stack fa-4x">
                    <i className="fa fa-circle fa-stack-2x text-primary"></i>
                    <i className="fa fa-bolt fa-stack-1x fa-inverse"></i>
                  </span>
                  <h4 className="service-heading">Superior on time service</h4>
                  <p className="text-muted">Safe, experienced drivers who work with you to ensure your freight arrives your destination in a timeley and risk free manner.</p>
                </div>
                <div className="col-md-4">
                  <span className="fa-stack fa-4x">
                    <i className="fa fa-circle fa-stack-2x text-primary"></i>
                    <i className="fa fa-map fa-stack-1x fa-inverse"></i>
                  </span>
                  <h4 className="service-heading">Flexible state coverage</h4>
                  <p className="text-muted">48 state coverage to ensure your freight gets where it needs to go.</p>
                </div>
              </div>
            </div>
          </section>
        )
    }
}

export default ServiceInfo
