import React, { Component } from 'react'

export class IntroDetails extends Component {
    render() {
        return (
            <header className="masthead">
            <div className="container">
              <div className="intro-text">
                <div className="intro-lead-in">Welcome To Best Way!</div>
                <a className="btn btn-primary btn-xl text-uppercase js-scroll-trigger" href="#services">Tell Me More</a>
              </div>
            </div>
          </header>
        )
    }
}

export default IntroDetails
